import {
    useStyles,
    ReadMore,
    useAppDisplayModeContext,
    useTheme,
} from '@archipro-design/aria';
import * as S from './AboutDescription.style';

import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';
import { useLoaderData } from '@remix-run/react';
import { useWebsiteTrackingProxy } from '~/modules/tracking/hook/useWebsiteTrackingProxy';
import { useIsDisabledInPreview } from '~/modules/root/hook/use-is-disabled-in-preview';

const MAX_LINES = {
    MOBILE: 15,
    DESKTOP: 15,
};

export const AboutDescription: React.FC = () => {
    const { css } = useStyles();
    const { mobile: isMobile } = useAppDisplayModeContext();
    const { professionalOverview } = useLoaderData<OverviewLoaderData>();
    const theme = useTheme();
    const content = professionalOverview?.BlockSummary;
    const preventOpenLink = useIsDisabledInPreview('profile_social_links');
    const clickProxy = useWebsiteTrackingProxy(
        {
            professionalID: professionalOverview.ID,
            professionalTitle: professionalOverview.Title,
            itemType: 'Professional',
        },
        { preventOpenLink }
    );

    if (!content) {
        return null;
    }

    return (
        <div className={css(S.aboutDescWrapper)}>
            <ReadMore
                maxLines={isMobile ? MAX_LINES.MOBILE : MAX_LINES.DESKTOP}
                content={
                    <span
                        dangerouslySetInnerHTML={{ __html: content }}
                        onClick={clickProxy}
                    />
                }
                readMoreText="View More"
                readLessText="View Less"
                variables={{
                    contentLineHeight: isMobile
                        ? 1.5
                        : theme.siteVariables.lineHeightSmall,
                }}
                className={css(S.ReadMoreText)}
            />

            <div className={css(S.readMoreDivider)}></div>
        </div>
    );
};
