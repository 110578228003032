import { MainInfoRightSectionItem } from './MainInfoRightSectionItem';
import { useAppDisplayModeContext, useStyles } from '@archipro-design/aria';
import * as S from './MainInformation.style';
import { useLoaderData } from '@remix-run/react';
import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';
import ClickToReveal from '@modules/root/component/click-to-reveal/ClickToReveal';
import InfoSectionMobile from '~/modules/detail/components/info-section/InfoSectionMobile';
import { useState } from 'react';
import { useClickToReveal } from '~/modules/root/hook/use-click-to-reveal';

export const ProfessionalPhoneNumbers: React.FC = () => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();
    const { professionalOverview } = useLoaderData<OverviewLoaderData>();
    const phoneNumber = professionalOverview?.Phone;
    const revealKey = `professional-${professionalOverview.ID}`;
    const { revealed: sessionRevealed } = useClickToReveal({
        revealType: 'Phone',
        revealKey,
    });
    const [revealed, setRevealed] = useState(!!sessionRevealed);
    if (!phoneNumber) {
        return null;
    }

    return mobile ? (
        <InfoSectionMobile label="Phone">
            <div className={css(S.ClickToRevealClass)}>
                <ClickToReveal
                    label={''}
                    fulltext={phoneNumber}
                    type={'Phone'}
                    buttonTrackingID={
                        professionalOverview.ID +
                        '-professional-home-phone-one-reveal'
                    }
                    onRevealed={() => setRevealed(true)}
                    revealKey={revealKey}
                />
            </div>
            {revealed && professionalOverview?.Phone2 && (
                <div className={css(S.ClickToRevealClass)}>
                    <ClickToReveal
                        label={''}
                        fulltext={professionalOverview.Phone2}
                        type={'Phone'}
                        buttonTrackingID={
                            professionalOverview.ID +
                            '-professional-home-phone-Two-reveal'
                        }
                        revealKey={revealKey}
                    />
                </div>
            )}
        </InfoSectionMobile>
    ) : (
        <MainInfoRightSectionItem label="Phone" className={css(S.PhoneSection)}>
            <div className={css(S.ClickToRevealClass)}>
                <ClickToReveal
                    label={''}
                    fulltext={phoneNumber}
                    type={'Phone'}
                    buttonTrackingID={
                        professionalOverview.ID +
                        '-professional-home-phone-one-reveal'
                    }
                    revealKey={revealKey}
                    onRevealed={() => setRevealed(true)}
                />
            </div>
            {revealed && professionalOverview?.Phone2 && (
                <div className={css(S.ClickToRevealClass)}>
                    <ClickToReveal
                        label={''}
                        fulltext={professionalOverview.Phone2}
                        type={'Phone'}
                        buttonTrackingID={
                            professionalOverview.ID +
                            '-professional-home-phone-Two-reveal'
                        }
                        revealKey={revealKey}
                    />
                </div>
            )}
        </MainInfoRightSectionItem>
    );
};
