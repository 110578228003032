import { Button, TileGrid, useStyles } from '@archipro-design/aria';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import * as S from './FeaturedItems.style';
import SaveToDesignBoard from '@modules/design-board/component/SaveToDesignBoard';
import { useSaveToDesignBoard } from '~/modules/design-board/hook/use-save-to-design-board';
import { Link } from '@remix-run/react';
import ShoppingAvailable from '~/modules/tile/component/product-tile/ShoppingAvailable';
import type { FeaturedProductsProps } from './FeaturedProducts';
import VariantDot from '~/modules/tile/component/product-tile/VariantDot';
import getProductPriceDisplayProps from '~/modules/tile/util/get-product-tile-price-display-props';
import ProductTile from '~/modules/tile/component/product-tile/ProductImpressionTile';
import { visitBMEditor } from '~/modules/directory/util/tile-helpers';
import DeleteItemConfirmModal from '~/modules/directory/component/DeleteItemConfirmModal';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { useDeleteItemWithConfirm } from '~/modules/directory/hook/use-delete-item-with-confirm';
import { useTileHotButtons } from '~/modules/root';

export const FeaturedProductsDesktop = ({
    products,
    openInNewTab,
    disableTileLink,
    disableSaveToDesignBoard,
}: FeaturedProductsProps) => {
    const { ID: professionalID, Title } = useProfessionalInitialRouteData();

    const { favouriteItem } = useSaveToDesignBoard('ProductPin');

    const { featuredProductsList, productCount } = products;

    const hasButton = productCount >= 5;

    const renderTileAs = disableTileLink ? 'div' : Link;

    const { css } = useStyles({ hasButton, disableTileLink });
    const { getTileTopActions, getTileAdminCaption, hotButtonModal } =
        useTileHotButtons({
            itemType: 'project',
            itemIDs: featuredProductsList.map((p) => p.id),
        });

    const {
        itemID: itemIDToDelete,
        willDelete,
        confirmDelete,
        cancelDelete,
    } = useDeleteItemWithConfirm();

    if (!featuredProductsList?.length) return null;

    const productTiles = featuredProductsList.map((n) => {
        const professionalName = Title;
        const productName = n.title;
        const { price, pricePrefix } = getProductPriceDisplayProps(
            n.pricingDisplay
        );

        const variants =
            n.colors?.map((c) => {
                return {
                    children: (
                        <VariantDot
                            size={13}
                            colorCode={c.code ?? ''}
                            image={c.dot?.Desktop}
                        />
                    ),
                };
            }) ?? [];

        return {
            ...n,
            professionalName,
            productName,
            price,
            pricePrefix,
            variants,
            href: n.link,
            professionalID,
        };
    });

    return (
        <div className={css(S.TileWrapper)}>
            <TileGrid
                heading={{
                    children: (
                        <>
                            Featured Products by
                            <br />
                            {Title}
                        </>
                    ),
                }}
                columns={'repeat(5, 1fr)'}
            >
                {productTiles?.map((p, i) => {
                    const topActions = getTileTopActions({
                        item: p,
                        onDeleteClick: () => willDelete(p.id),
                        editOnSameTab: openInNewTab,
                    });

                    const adminCaption = getTileAdminCaption(p.id);
                    return (
                        <ProductTile
                            key={`featured-products-product-tile-${i}`}
                            className={css(S.ProductTile)}
                            image={[
                                {
                                    src: p?.images[0]?.src || '',
                                    mixBlendMode:
                                        p?.images[0]?.mixBlendMode || 'normal',
                                    alt: p.productName,
                                    sizes: getImagesSizes(348),
                                },
                            ]}
                            professionalName={''}
                            productName={p.productName}
                            variants={p.variants}
                            price={p.price}
                            pricePrefix={p.pricePrefix}
                            size={'large'}
                            newDesignEnabled={true}
                            as={renderTileAs}
                            to={disableTileLink ? undefined : p.href}
                            {...(!disableTileLink && { prefetch: 'intent' })}
                            target={openInNewTab ? '_blank' : '_self'}
                            hideFavourite={disableSaveToDesignBoard}
                            onFavourite={(e) => favouriteItem.onClick(e, p.id)}
                            onClick={() => {
                                if (disableTileLink && Boolean(p.canEdit)) {
                                    visitBMEditor(p, true);
                                }
                            }}
                            isFavourited={favouriteItem.favourited(p.id)}
                            topContent={
                                p.canPurchase
                                    ? [
                                          <ShoppingAvailable
                                              key="shopping-available"
                                              floating={'left'}
                                          />,
                                      ]
                                    : []
                            }
                            impression={{
                                type: 'LibraryItem_Directory',
                                itemID: p.id,
                                professionalID: p.professionalID,
                            }}
                            topActions={topActions}
                            adminCaption={adminCaption}
                        />
                    );
                })}
            </TileGrid>

            {hasButton && (
                <div className={css(S.ButtonSection)}>
                    <Button
                        as={Link}
                        outlined={true}
                        size={16}
                        to={`./our-products`}
                    >
                        VIEW MORE PRODUCTS
                    </Button>
                </div>
            )}

            {!disableSaveToDesignBoard && favouriteItem.target && (
                <SaveToDesignBoard {...favouriteItem} />
            )}
            {itemIDToDelete && (
                <DeleteItemConfirmModal
                    title={'Delete product'}
                    content={
                        'Are you sure you would like to delete this product? All information and associated tags will be lost.'
                    }
                    open={true}
                    onCancel={cancelDelete}
                    onConfirm={confirmDelete}
                />
            )}
            {hotButtonModal}
        </div>
    );
};
