import type { StyleRule } from '@archipro-design/aria';
import { labelClassName } from '@archipro-design/aria';
import { listItemClassName } from '@archipro-design/aria';
import { flexClassName, pxArrayToRem } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const LocationSection: StyleRule = ({ theme }) => ({
    [`& .${flexClassName}`]: {
        margin: pxArrayToRem([15, 0, 11, 0]),
    },

    [theme.screen.laptop]: {
        [`& .${flexClassName}`]: {
            margin: 0,
        },
    },
});

export const LocationSectionMobile: StyleRule = ({ theme }) => ({
    [`& .${listItemClassName}`]: {
        gridTemplateColumns: '100rem 1fr',
    },

    [`& a`]: {
        textUnderlineOffset: pxToRem(2),
        textDecoration: 'underline',
        color: theme.siteVariables.colors.charcoal[250],
    },
});

export const LocationFindButton: StyleRule = ({ theme }) => ({
    [`& .${labelClassName}`]: {
        fontSize: pxToRem(14),
    },
    color: theme.siteVariables.colors.charcoal[250],

    marginTop: pxToRem(5),
    textAlign: 'left',

    [theme.screen.laptop]: {
        textAlign: 'inherit',
        color: theme.siteVariables.colorScheme.default?.foreground,
        margin: pxArrayToRem([0, 0, 7, 0]),
        padding: 0,
        '&:hover': {
            textDecoration: 'unset',
        },
    },

    '& div': {
        textUnderlineOffset: pxToRem(2),
        textDecoration: 'underline',
        [theme.screen.laptop]: {
            textDecoration: 'underline',
            fontWeight: theme.siteVariables.fontWeightRegular,
        },
    },
});

export const LocationFindText: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.charcoal[250],

    fontSize: pxToRem(14),
    marginTop: pxToRem(5),
    width: pxToRem(186),

    [theme.screen.laptop]: {
        fontSize: pxToRem(16),
        width: '100%',
        margin: pxArrayToRem([0, 0, 7, 0]),
    },

    [`& a`]: {
        textUnderlineOffset: pxToRem(2),
        textDecoration: 'underline',
    },
});

export const LocationViewAllLink: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(5),
    '& a': {
        textDecoration: 'underline',
        textDecorationSkipInk: 'none',
        ':hover': {
            color: theme.siteVariables.colorScheme.default?.foregroundHover,
        },
    },
    [theme.screen.tablet]: {
        marginTop: 0,
        '& a': {
            ':hover': {
                color: theme.siteVariables.colorScheme.default?.foregroundHover,
            },
        },
    },
});

export const LocationUnderHeader: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(16),
    fontSize: pxToRem(18),
    display: 'inline-block',
    '& a': {
        color: theme.siteVariables.colorScheme.default?.foreground,
        textDecoration: 'underline',
        ':hover': {
            color: theme.siteVariables.colorScheme.default?.foregroundHover,
        },
    },

    [theme.screen.laptop]: {
        marginTop: 0,
        fontSize: pxToRem(16),
    },
});
