import { useAppDisplayModeContext } from '@archipro-design/aria';
import { MainInfoRightSectionItem } from './MainInfoRightSectionItem';
import InfoSectionMobile from '~/modules/detail/components/info-section/InfoSectionMobile';
import AvailableForWork from '../../shared/AvailableForWork';

const Availability = () => {
    const { mobile } = useAppDisplayModeContext();
    return mobile ? (
        <InfoSectionMobile label="Availability">
            <AvailableForWork mobile />
        </InfoSectionMobile>
    ) : (
        <MainInfoRightSectionItem label="Availability">
            <AvailableForWork />
        </MainInfoRightSectionItem>
    );
};

export default Availability;
