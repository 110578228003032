import { useAppDisplayModeContext } from '@archipro-design/aria';
import { MainInfoRightSectionItem } from './MainInfoRightSectionItem';

import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';
import { useLoaderData } from '@remix-run/react';
import InfoSectionMobile from '~/modules/detail/components/info-section/InfoSectionMobile';

export const YearFounded: React.FC = () => {
    const { professionalOverview } = useLoaderData<OverviewLoaderData>();

    const { mobile } = useAppDisplayModeContext();
    const yearFounded = professionalOverview?.YearFounded;
    if (!yearFounded) {
        return null;
    }

    return mobile ? (
        <InfoSectionMobile label="Year founded">
            {yearFounded}
        </InfoSectionMobile>
    ) : (
        <MainInfoRightSectionItem label="Year founded">
            {yearFounded}
        </MainInfoRightSectionItem>
    );
};
