import {
    useStyles,
    useAppDisplayModeContext,
    Grid,
    pxToRem,
} from '@archipro-design/aria';

import * as S from '@modules/professional/component/page-layout/PageLayout.style';
import PageSections from '@modules/professional/component/page-layout/PageSections';

import { MainInformation } from '@modules/professional/component/overview-tab/main-info/MainInformation';
import { BannerAndLogo } from '@modules/professional/component/overview-tab/banner-and-logo/BannerAndLogo';
import { Downloads } from '@modules/professional/component/overview-tab/downloads/Downloads';

import {
    FeaturedProducts,
    FeaturedProjects,
} from '@modules/professional/component/overview-tab/featured-items';
import type { SerializeFrom } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { convertLocationData } from '../util/contact-page-helper';
import { BannerSegmentEnum } from 'generated/graphql';
import type { LocationDataType } from '@modules/professional/type';

import type { APHandle } from '@modules/root';
import { useMatchesHandleData } from '@modules/root';
import invariant from 'tiny-invariant';
import { useLocationsSortByDistance } from '~/modules/professional/hook/use-locations-sort-by-distance';
import { useIsDisabledInPreview } from '@modules/root/hook/use-is-disabled-in-preview';
import type { loader } from '~/modules/professional/page/loaders/overview-page-loader.server';

export type OverviewLoaderData = SerializeFrom<typeof loader>;
export type ProfessionalBannersData = OverviewLoaderData['banners'];

export const handle: APHandle = {
    showBannerAndLogoMobile: true,
    isOverviewPage: true,
};

const OverviewPage = () => {
    const { css } = useStyles();
    const { desktop } = useAppDisplayModeContext();
    const {
        professionalOverview,
        featuredProjects,
        featuredProducts,
        banners,
        awards,
    } = useLoaderData<typeof loader>();

    const isBMPreview = useMatchesHandleData('isBMPreview', false);

    const disableTileLink = useIsDisabledInPreview('featured_tile_links');
    const disableSaveToDesignBoard =
        useIsDisabledInPreview('save_design_board');
    const disabledSocialLinks = useIsDisabledInPreview('profile_social_links');
    const disabledSendEnquiry = useIsDisabledInPreview('profile_send_enquiry');
    const disableDownloads = useIsDisabledInPreview('profile_downloads');

    const {
        ID: professionalId,
        EnquiryResponseRate: responseRate,
        LibraryLocations,
    } = useProfessionalInitialRouteData();

    const { sortedLocations, calculateDistance } =
        useLocationsSortByDistance<LocationDataType>({
            locations: LibraryLocations.map(convertLocationData),
            skip: true,
        });

    invariant(professionalId, 'Professional ID must be defined.');

    const hasFeaturedProjects =
        !professionalOverview?.IsManufacturer &&
        featuredProjects?.projectCount > 0;
    const hasFeaturedProducts =
        featuredProducts?.productCount > 0 &&
        professionalOverview?.IsManufacturer;

    const excludeAboutBanner = banners.filter(
        (b) => b.bannerSegment !== BannerSegmentEnum.Aboutus
    );

    return (
        <div className={css(S.PageContent)}>
            {desktop && (
                <BannerAndLogo banners={excludeAboutBanner} awards={awards} />
            )}
            <div className={css(S.Container)}>
                <PageSections>
                    <Grid
                        columns={1}
                        className={css(
                            desktop
                                ? S.Sections
                                : {
                                      gridGap: pxToRem(60),
                                  }
                        )}
                    >
                        <MainInformation
                            locations={sortedLocations}
                            responseRate={responseRate}
                            calculateDistance={calculateDistance}
                            showProducts={
                                professionalOverview?.IsManufacturer &&
                                professionalOverview.ProductCount > 0
                            }
                            disabledSocialLinks={disabledSocialLinks}
                            disabledSendEnquiry={disabledSendEnquiry}
                            disableSaveToDesignBoard={disableSaveToDesignBoard}
                        />

                        {hasFeaturedProducts && (
                            <FeaturedProducts
                                products={featuredProducts}
                                openInNewTab={isBMPreview}
                                disableTileLink={disableTileLink}
                                disableSaveToDesignBoard={
                                    disableSaveToDesignBoard
                                }
                            />
                        )}

                        {hasFeaturedProjects && (
                            <FeaturedProjects
                                projects={featuredProjects}
                                openInNewTab={isBMPreview}
                                disableTileLink={disableTileLink}
                                disableSaveToDesignBoard={
                                    disableSaveToDesignBoard
                                }
                            />
                        )}

                        {!disableDownloads && <Downloads />}
                    </Grid>
                </PageSections>
            </div>
        </div>
    );
};
export default OverviewPage;
