import { Flex, Header, Label, useStyles } from '@archipro-design/aria';
import { Link } from '@remix-run/react';
import { Divider } from './Divider';
import * as S from './MainInformation.style';

interface ItemProps {
    title: string;
    link?: string;
    subTitle?: string;
    children?: React.ReactNode;
    totalNumber?: number;
    numberText?: string;
    className?: string;
    bottomComponent?: React.ReactNode;
}

export const MainInfoLeftSectionItem: React.FC<ItemProps> = ({
    title,
    link,
    subTitle,
    totalNumber,
    numberText = '',
    className = '',
    bottomComponent,
    children,
}) => {
    const { css } = useStyles();
    return (
        <div className={className}>
            <Divider />
            <Label variant="01" uc={true} className={css(S.LabelText)}>
                {title}
            </Label>
            {subTitle && (
                <Label variant="01" uc={true} className={css(S.LabelText)}>
                    {subTitle}
                </Label>
            )}
            {children}
            {totalNumber && (
                <Flex hAlign="end">
                    <Header
                        variant="04"
                        as="h3"
                        link={!!link}
                        className={css(S.LabelNumber)}
                    >
                        {link ? (
                            <Link to={link} prefetch="intent">
                                {`${totalNumber.toString()}${numberText}`}
                            </Link>
                        ) : (
                            `${totalNumber.toString()}${numberText}`
                        )}
                    </Header>
                </Flex>
            )}
            {bottomComponent && (
                <Label variant="01" uc={true} className={css(S.LabelText)}>
                    {bottomComponent}
                </Label>
            )}
        </div>
    );
};
