import { useStyles } from '@archipro-design/aria';
import { useIsSafari } from '@modules/root/hook/use-is-safari';
import * as S from './MainInformation.style';
import type { ProfessionalInitialResult } from '~/modules/professional/hook/use-professional-initial-route-data';
import { EnquiryInline } from '~/modules/enquiry/component/enquiry-inline/EnquiryInline';
import { useSaveToDesignBoard } from '~/modules/design-board';
import { useProfessionalInitialRouteData } from '~/modules/professional/hook/use-professional-initial-route-data';
import { useABTestValue } from '~/modules/root/hook/use-growthbook';
import { LoginEnquiryInline } from '~/modules/enquiry/component/login-enquiry-inline/LoginEnquiryInline';
interface OverviewEnquiryFormData {
    Professional: ProfessionalInitialResult;
}

interface OverviewEnquiryFormProps {
    data: OverviewEnquiryFormData;
    disabledSendEnquiry?: boolean;
    disableSaveToDesignBoard?: boolean;
    showAddToDeisgnBoardInEnquiryForm?: boolean;
}

export const OverviewEnquiryForm = ({
    data: { Professional },
    disabledSendEnquiry,
    disableSaveToDesignBoard,
}: OverviewEnquiryFormProps) => {
    const isSafari = useIsSafari();
    const { css } = useStyles({ isSafari });
    const { favouriteItem } = useSaveToDesignBoard(
        'ProfessionalPin',
        Professional.ID
    );
    const { primaryCTA } = useProfessionalInitialRouteData();
    const enquiryVariantControl = useABTestValue('upgraded-enquiry-flow');
    const isControlVariant = enquiryVariantControl === 'control';

    return (
        <div className={css(S.OverviewEnquiryFormContainer)}>
            {isControlVariant ? (
                <EnquiryInline
                    professional={Professional}
                    disableSubmit={disabledSendEnquiry}
                    disableSaveToDesignBoard={disableSaveToDesignBoard}
                    favouriteItem={favouriteItem}
                    showCustomEnquiryText={!!Professional.CustomEnquiryMessage}
                    submitButtonText={
                        primaryCTA?.primaryCTALabel ?? 'SEND ENQUIRY'
                    }
                    siteTreeID={Professional.ID}
                    itemType="professional"
                />
            ) : (
                <LoginEnquiryInline
                    professional={Professional}
                    enquiryVariantControl={enquiryVariantControl}
                    disableSubmit={disabledSendEnquiry}
                    showCustomEnquiryText={!!Professional.CustomEnquiryMessage}
                    itemType="professional"
                />
            )}
        </div>
    );
};
