import { useAppDisplayModeContext } from '@archipro-design/aria';
import { useLoaderData } from '@remix-run/react';
import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';
import { MainInfoLeftSectionItem } from '@modules/professional/component/overview-tab/main-info/MainInfoLeftSectionItem';
import InfoSectionMobile from '~/modules/detail/components/info-section/InfoSectionMobile';
import { useMatchesHandleData } from '@modules/root';

export const ProductsListed: React.FC = () => {
    const { professionalOverview } = useLoaderData<OverviewLoaderData>();
    const { mobile: isMobile } = useAppDisplayModeContext();
    const isBMPreview = useMatchesHandleData('isBMPreview', false);

    const productsListed = professionalOverview?.ProductCount || 0;
    if (productsListed <= 0) {
        return null;
    }

    return isMobile ? (
        <InfoSectionMobile label="Products listed">
            {productsListed}
        </InfoSectionMobile>
    ) : (
        <MainInfoLeftSectionItem
            title="Products"
            subTitle="listed"
            totalNumber={productsListed}
            link={
                isBMPreview
                    ? 'our-products'
                    : `${professionalOverview.Link}/our-products`
            }
        />
    );
};
