import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const Indicator: StyleRule = ({ theme }) => ({
    width: pxToRem(13),
    height: pxToRem(13),
    borderRadius: '50%',
    backgroundColor: theme.siteVariables.naturalColors.green[200],
    marginRight: theme.siteVariables.spaces['space-8'],
});
