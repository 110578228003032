import {
    List,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import { useLoaderData } from '@remix-run/react';
import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';
import { MainInfoLeftSectionItem } from './MainInfoLeftSectionItem';
import * as S from './ServicesWeProvide.style';
import InfoSectionMobile from '~/modules/detail/components/info-section/InfoSectionMobile';

const SHOW_MORE_NUMBER = 5;

const getListItems = (items: string[]) => {
    return items.map((item) => {
        return { label: item };
    });
};

export const ServicesWeProvide: React.FC = () => {
    const { mobile: isMobile } = useAppDisplayModeContext();

    const { professionalOverview } = useLoaderData<OverviewLoaderData>();

    const listItems = getListItems(professionalOverview?.Services || []);

    const { css } = useStyles({
        hasShowMoreLink: listItems.length > SHOW_MORE_NUMBER,
    });
    return listItems.length > 0 ? (
        isMobile ? (
            <InfoSectionMobile
                label="Services we provide"
                showMoreText={`Show all services (${listItems.length})`}
                showLessText="Show less"
                lineLimit={SHOW_MORE_NUMBER}
            >
                <div>
                    {listItems.map((li) => (
                        <div key={li.label}>{li.label}</div>
                    ))}
                </div>
            </InfoSectionMobile>
        ) : (
            <MainInfoLeftSectionItem
                title="Services we"
                subTitle="provide"
                totalNumber={listItems.length}
            >
                <List
                    items={listItems}
                    lineLimit={SHOW_MORE_NUMBER}
                    showMoreText="More"
                    showLessText="Less"
                    className={css(S.ServiceList)}
                />
            </MainInfoLeftSectionItem>
        )
    ) : null;
};
