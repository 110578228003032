import {
    Button,
    pxArrayToRem,
    pxToRem,
    TileGrid,
    useStyles,
} from '@archipro-design/aria';
import { Link, useNavigate } from '@remix-run/react';
import * as S from './FeaturedItems.style';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { useSaveToDesignBoard } from '~/modules/design-board/hook/use-save-to-design-board';
import SaveToDesignBoard from '@modules/design-board/component/SaveToDesignBoard';
import type { FeaturedProjectsProps } from './FeaturedProjects';
import ProjectTile from '~/modules/tile/component/project-tile/ProjectImpressionTile';
import { visitBMEditor } from '~/modules/directory/util/tile-helpers';
import DeleteItemConfirmModal from '~/modules/directory/component/DeleteItemConfirmModal';
import { useDeleteItemWithConfirm } from '~/modules/directory/hook/use-delete-item-with-confirm';
import { useMatchesHandleData, useTileHotButtons } from '~/modules/root';

export const FeaturedProjectsDesktop = ({
    projects,
    openInNewTab,
    disableTileLink,
    disableSaveToDesignBoard,
}: FeaturedProjectsProps) => {
    const professional = useProfessionalInitialRouteData();
    const { featuredProjectsList, projectCount } = projects;
    const navigate = useNavigate();
    const isProfessionalProfile = useMatchesHandleData(
        'isProfessionalProfile',
        false
    );

    const hasButton = projectCount > 3;

    const renderTileAs = disableTileLink ? 'div' : Link;

    const { favouriteItem } = useSaveToDesignBoard('ProjectPin');

    const { css } = useStyles({ hasButton, disableTileLink });

    const { getTileTopActions, getTileAdminCaption, hotButtonModal } =
        useTileHotButtons({
            itemType: 'project',
            itemIDs: featuredProjectsList.map((p) => p.id),
        });

    const {
        itemID: itemIDToDelete,
        willDelete,
        confirmDelete,
        cancelDelete,
    } = useDeleteItemWithConfirm();

    if (!featuredProjectsList?.length) return null;

    const projectTiles = featuredProjectsList.map((n) => {
        return {
            ...n,
            projectLink: n.link,
            professionalName: professional.Title,
            projectName: n.title,
        };
    });

    return (
        <div className={css(S.TileWrapper)}>
            <TileGrid
                heading={{
                    children: (
                        <>
                            Featured Projects by
                            <br />
                            {professional.Title}
                        </>
                    ),
                }}
                columns={'repeat(3, 1fr)'}
            >
                {projectTiles?.map((p) => {
                    const topActions = getTileTopActions({
                        item: p,
                        onDeleteClick: () => willDelete(p.id),
                        editOnSameTab: openInNewTab,
                    });

                    const adminCaption = getTileAdminCaption(p.id);

                    return (
                        <ProjectTile
                            key={p.id}
                            className={css(S.ProjectTile)}
                            image={[
                                {
                                    src: p?.images[0] || '',
                                    alt: professional.Title,
                                },
                            ]}
                            style={{
                                textDecoration: 'none',
                            }}
                            professionalName={p.professionalName}
                            projectName={{
                                children: p.projectName,
                                onClick: () => {
                                    if (disableTileLink) {
                                        if (p.canEdit) {
                                            visitBMEditor(p, true);
                                        }
                                    } else {
                                        navigate(p.projectLink);
                                    }
                                },
                            }}
                            onClick={() => {
                                if (disableTileLink && Boolean(p.canEdit)) {
                                    visitBMEditor(p, true);
                                }
                            }}
                            size={'large'}
                            newDesignEnabled={true}
                            onFavourite={(e) => favouriteItem.onClick(e, p.id)}
                            isFavourited={favouriteItem.favourited(p.id)}
                            as={renderTileAs}
                            to={disableTileLink ? undefined : p.projectLink}
                            {...(!disableTileLink && { prefetch: 'intent' })}
                            target={
                                openInNewTab
                                    ? '_blank'
                                    : isProfessionalProfile
                                      ? undefined
                                      : '_self'
                            }
                            hideFavourite={disableSaveToDesignBoard}
                            variables={{
                                tilePadding: hasButton
                                    ? pxArrayToRem([14, 14, 0, 14])
                                    : pxToRem(14),
                            }}
                            impression={{
                                type: 'LibraryItem_Directory',
                                itemID: p.id,
                                professionalID: professional.ID,
                            }}
                            topActions={topActions}
                            adminCaption={adminCaption}
                            state={
                                isProfessionalProfile
                                    ? { visitFrom: 'profile' }
                                    : null
                            }
                        />
                    );
                })}
            </TileGrid>
            {projectCount && projectCount >= 3 && (
                <div className={css(S.ButtonSection)}>
                    <Button
                        outlined={true}
                        size={16}
                        as={Link}
                        to={`./case-studies`}
                    >
                        VIEW MORE PROJECTS
                    </Button>
                </div>
            )}

            {!disableSaveToDesignBoard && favouriteItem.target && (
                <SaveToDesignBoard {...favouriteItem} />
            )}
            {itemIDToDelete && (
                <DeleteItemConfirmModal
                    title={'Delete project'}
                    content={
                        'Are you sure you would like to delete this project? All information and associated tags will be lost.'
                    }
                    open={true}
                    onCancel={cancelDelete}
                    onConfirm={confirmDelete}
                />
            )}
            {hotButtonModal}
        </div>
    );
};
