import { useAppDisplayModeContext } from '@archipro-design/aria';
import { FeaturedProjectsMobile } from '@modules/professional/component/overview-tab/featured-items/FeaturedProjects.mobile';
import { FeaturedProjectsDesktop } from '@modules/professional/component/overview-tab/featured-items/FeaturedProjects.desktop';
import type { OverviewLoaderData } from '~/modules/professional/page/OverviewPage';

export interface FeaturedProjectsProps {
    projects: OverviewLoaderData['featuredProjects'];
    openInNewTab?: boolean;
    disableTileLink?: boolean;
    disableSaveToDesignBoard?: boolean;
}

export const FeaturedProjects = (props: FeaturedProjectsProps) => {
    const { desktop } = useAppDisplayModeContext();

    if (desktop) {
        return <FeaturedProjectsDesktop {...props} />;
    }

    return <FeaturedProjectsMobile {...props} />;
};
