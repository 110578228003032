import type { StyleRule } from '@archipro-design/aria';
import { listClassName, labelClassName } from '@archipro-design/aria';
import { flexClassName } from '@archipro-design/aria';
import { listSlotClassNames } from '@archipro-design/aria';
import { captionClassName, pxArrayToRem } from '@archipro-design/aria';
import { listItemClassName } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const ServiceSection: StyleRule = ({ theme }) => ({
    [`& .${flexClassName}`]: {
        margin: pxArrayToRem([15, 0, 3, 0]),
        [theme.screen.laptop]: {
            [`& .${labelClassName}`]: {
                paddingTop: 0,
            },
        },
    },
    [`& .${listClassName}`]: {
        ':first-child': {
            borderTop: 0,
        },
    },
});

export const ServiceList: StyleRule = ({ theme, hasShowMoreLink }) => ({
    padding: pxArrayToRem([0, 0, 6, 0]),
    marginBottom: pxToRem(hasShowMoreLink ? 0 : -10),
    ':first-child': {
        borderTop: 0,
    },

    [theme.screen.laptop]: {
        padding: pxArrayToRem([68, 0, 8, 0]),
        marginBottom: 0,
    },

    [`& .${listItemClassName}`]: {
        border: 0,
        minHeight: pxToRem(24),
        marginBottom: pxToRem(16),
        [theme.screen.laptop]: {
            minHeight: 'unset',
            marginBottom: pxToRem(8),
        },

        [`& .${captionClassName}`]: {
            fontSize: pxToRem(16),
            lineHeight: 1.5,

            [theme.screen.laptop]: {
                lineHeight: 1.1,
                letterSpacing: theme.siteVariables.letterSpacingMedium,
            },
        },

        [`&.${listSlotClassNames.moreOrLess}`]: {
            marginTop: pxToRem(-2),
            marginBottom: pxToRem(1),
            [theme.screen.laptop]: {
                marginTop: 'inherit',
                marginBottom: pxToRem(18),
            },

            [`& .${captionClassName}`]: {
                cursor: 'pointer',
                textDecoration: 'underline',
                textDecorationSkipInk: 'none',
                ':hover': {
                    color: theme.siteVariables.colorScheme.default
                        ?.foregroundHover,
                },
                [theme.screen.laptop]: {
                    ...theme.siteVariables.textStyles.Text.Medium65,
                },
            },
        },
    },
});
