import { Button, useStyles, Grid } from '@archipro-design/aria';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import * as S from './FeaturedItems.style';
import SaveToDesignBoard from '@modules/design-board/component/SaveToDesignBoard';
import { useSaveToDesignBoard } from '~/modules/design-board/hook/use-save-to-design-board';
import PageSection from '../../page-layout/PageSection';
import { Link } from '@remix-run/react';
import ShoppingAvailable from '~/modules/tile/component/product-tile/ShoppingAvailable';

import type { FeaturedProductsProps } from './FeaturedProducts';
import VariantDot from '~/modules/tile/component/product-tile/VariantDot';
import getProductPriceDisplayProps from '~/modules/tile/util/get-product-tile-price-display-props';
import ProductTile from '~/modules/tile/component/product-tile/ProductImpressionTile';

export const FeaturedProductsMobile = ({
    products,
    openInNewTab,
    disableTileLink,
    disableSaveToDesignBoard,
}: FeaturedProductsProps) => {
    const { css } = useStyles();
    const {
        ID: professionalID,
        Title,
        BaseUrl,
    } = useProfessionalInitialRouteData();

    const renderTileAs = disableTileLink ? 'div' : Link;

    const { favouriteItem } = useSaveToDesignBoard('ProductPin');

    const { featuredProductsList, productCount } = products;

    if (!featuredProductsList?.length) return null;

    const productTiles = featuredProductsList.map((n) => {
        const images = n.images;
        const professionalName = Title;
        const productName = n.title;
        const { price, pricePrefix } = getProductPriceDisplayProps(
            n.pricingDisplay
        );

        const variants =
            n.colors?.map((c) => {
                return {
                    children: (
                        <VariantDot
                            size={9}
                            colorCode={c.code ?? ''}
                            image={c.dot?.Desktop}
                        />
                    ),
                };
            }) ?? [];

        const productInfo = {
            images,
            professionalName,
            productName,
            price,
            pricePrefix,
            variants,
            id: n.id,
            href: n.link,
            canPurchase: n.canPurchase,
            professionalID,
        };
        return productInfo;
    });

    return (
        <div>
            <PageSection sectionTitle={`Featured products`}>
                <Grid columns={2} className={css(S.MobileProductTileWrapper)}>
                    {productTiles?.map((p, i) => {
                        return (
                            <ProductTile
                                as={renderTileAs}
                                key={`featured-products-product-tile-${i}`}
                                style={{ display: 'inline-grid' }}
                                image={[
                                    {
                                        src: p?.images[0]?.src || '',
                                        mixBlendMode:
                                            p?.images[0]?.mixBlendMode ||
                                            'normal',
                                        alt: p.productName,
                                    },
                                ]}
                                professionalName={''}
                                productName={p.productName}
                                variants={p.variants}
                                pricePrefix={p.pricePrefix}
                                price={p.price}
                                size="medium"
                                variant="02"
                                newDesignEnabled={true}
                                to={disableTileLink ? undefined : p.href}
                                {...(!disableTileLink && {
                                    prefetch: 'intent',
                                })}
                                target={openInNewTab ? '_blank' : '_self'}
                                hideFavourite={disableSaveToDesignBoard}
                                onFavourite={(e) =>
                                    favouriteItem.onClick(e, p.id)
                                }
                                isFavourited={favouriteItem.favourited(p.id)}
                                topContent={
                                    p.canPurchase
                                        ? [
                                              <ShoppingAvailable
                                                  key="shopping-available"
                                                  floating={'left'}
                                              />,
                                          ]
                                        : []
                                }
                                impression={{
                                    type: 'LibraryItem_Directory',
                                    itemID: p.id,
                                    professionalID: professionalID,
                                }}
                            />
                        );
                    })}
                </Grid>
                {productCount && productCount >= 5 && (
                    <Button
                        as={Link}
                        color="white"
                        size={24}
                        to={`${BaseUrl}/our-products`}
                        outlined={true}
                        transparent={true}
                        className={css(S.ViewAllButton)}
                    >
                        VIEW ALL PRODUCTS
                    </Button>
                )}

                {!disableSaveToDesignBoard && favouriteItem.target && (
                    <SaveToDesignBoard {...favouriteItem} />
                )}
            </PageSection>
        </div>
    );
};
