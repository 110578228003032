import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem } from '@archipro-design/aria';
import { pxToRem, imageClassName } from '@archipro-design/aria';

export const AssociationIcons: StyleRule = ({ theme }) => ({
    maxWidth: pxToRem(156),
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: `${pxToRem(12)} 0`,
    '& a > div': {
        width: pxToRem(40),
        height: pxToRem(40),
        border: 0,
    },

    marginBottom: pxToRem(2),

    [`& .${imageClassName}`]: {
        width: '100%',
        height: '100%',
    },

    [theme.screen.laptop]: {
        maxWidth: pxToRem(590),
        gridTemplateColumns: 'repeat(6, 1fr)',
        gap: pxToRem(14),
        margin: pxArrayToRem([-11, 0]),
    },
});

export const AssociationIconsMobile: StyleRule = () => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    '& > a': {
        marginBottom: pxToRem(10),
    },
});

export const AssociationAvatarMobile: (gridColumnStart?: number) => StyleRule =
    (gridColumnStart) =>
    ({ theme }) => {
        return {
            width: pxToRem(36),
            height: pxToRem(36),
            '& > div': {
                width: pxToRem(36),
                height: pxToRem(36),
                '& > span': {
                    display: 'block',
                    width: pxToRem(25),
                    height: pxToRem(25),
                },
            },
            '& img': {
                width: pxToRem(25),
                height: pxToRem(25),
            },

            [theme.screen.max.laptop]: {
                gridColumnStart: gridColumnStart || 'auto',
            },
        };
    };
