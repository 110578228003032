import { Link, useLoaderData } from '@remix-run/react';
import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';
import { MainInfoRightSectionItem } from '../main-info/MainInfoRightSectionItem';
import {
    Grid,
    Avatar,
    useStyles,
    useAppDisplayModeContext,
    pxToRem,
} from '@archipro-design/aria';
import * as S from './Associations.style';
import InfoSectionMobile from '~/modules/detail/components/info-section/InfoSectionMobile';
import { useMatchesHandleData } from '~/modules/root';

export const Associations: React.FC = () => {
    const { css } = useStyles();
    const { associations } = useLoaderData<OverviewLoaderData>();
    const { mobile: isMobile } = useAppDisplayModeContext();
    const numPerLine = isMobile ? 3 : 6;
    const iconSize = isMobile ? 32 : 40;
    const isBMPreview = useMatchesHandleData('isBMPreview', false);

    const totalNumber = associations?.nodes?.length || 0;

    if (!totalNumber) {
        return null;
    }

    const associationsToShow =
        associations?.nodes?.filter((a) => a.CompanyLogo) ?? [];

    return isMobile ? (
        <InfoSectionMobile
            label="Associations"
            rightSectionClassName={css(S.AssociationIconsMobile)}
        >
            {associationsToShow.length > 0 &&
                associationsToShow.map((a, index) => {
                    const logoBackgroundColor =
                        a?.LogoBackgroundColor ?? '#E8E9EB';
                    const gridCols = 6;
                    const remainders = associationsToShow.length % gridCols;
                    const indexToAdjust =
                        associationsToShow.length - remainders;
                    const gridColumnStart =
                        index === indexToAdjust
                            ? gridCols - remainders + 1
                            : undefined;

                    return (
                        <Link
                            key={a.ID}
                            className={css(
                                S.AssociationAvatarMobile(gridColumnStart)
                            )}
                            to={`/professional/${a.URLSegment}`}
                            prefetch="intent"
                        >
                            <Avatar
                                image={a.CompanyLogo}
                                size={32}
                                imageFit="contain"
                                multiply={!a?.LogoBackgroundColor}
                                styles={{
                                    borderWidth: 0,
                                }}
                                variables={{
                                    avatarBackgroundColor: logoBackgroundColor,

                                    avatarImageBackgroundColor:
                                        logoBackgroundColor,
                                    avatarPadding: pxToRem(5),
                                }}
                            />
                        </Link>
                    );
                })}
        </InfoSectionMobile>
    ) : (
        <MainInfoRightSectionItem label="Associations">
            <Grid className={css(S.AssociationIcons)} columns={numPerLine}>
                {associationsToShow.map((association) => {
                    const logoBackgroundColor =
                        association?.LogoBackgroundColor ?? '#E8E9EB';

                    return (
                        <div key={association.ID}>
                            {association.CompanyLogo && (
                                <Link
                                    to={`/professional/${association.URLSegment}`}
                                    prefetch="intent"
                                    target={isBMPreview ? '_blank' : undefined}
                                >
                                    <Avatar
                                        image={association.CompanyLogo}
                                        size={iconSize}
                                        imageFit={'contain'}
                                        multiply={
                                            !association?.LogoBackgroundColor
                                        }
                                        styles={{
                                            borderWidth: 0,
                                        }}
                                        variables={{
                                            avatarBackgroundColor:
                                                logoBackgroundColor,

                                            avatarImageBackgroundColor:
                                                logoBackgroundColor,
                                            avatarPadding: pxToRem(6),
                                        }}
                                    />
                                </Link>
                            )}
                        </div>
                    );
                })}
            </Grid>
        </MainInfoRightSectionItem>
    );
};
