import type { StyleRule } from '@archipro-design/aria';
import { tileGridClassName } from '@archipro-design/aria';
import { projectTileClassName } from '@archipro-design/aria';
import { productTileClassName } from '@archipro-design/aria';
import { productTileSlotClassNames } from '@archipro-design/aria';
import { tileImageClassName } from '@archipro-design/aria';
import { boxClassName } from '@archipro-design/aria';
import { buttonClassName } from '@archipro-design/aria';
import { projectTileSlotClassNames } from '@archipro-design/aria';
import {
    pxToRem,
    labelClassName,
    flexClassName,
    pxArrayToRem,
    HeaderClassName,
} from '@archipro-design/aria';

export const HeaderTitle: StyleRule = ({ theme }) => ({
    [`&.${HeaderClassName}`]: {
        textAlign: 'left',
        color: theme.siteVariables.colorScheme.default!.foreground,
        margin: 0,
        textTransform: 'uppercase',
        fontSize: pxToRem(58),
        fontWeight: 500,
    },
});

export const HeaderTitleBottom: StyleRule = ({ theme }) => ({
    ...HeaderTitle({ theme }),
    marginBottom: pxToRem(76),
});

export const ProductTile: StyleRule<{
    disableTileLink?: boolean;
}> = ({ disableTileLink, theme }) => ({
    display: 'inline-block',
    width: '100%',
    '& > *': {
        color: theme.siteVariables.colorScheme.default!.foreground,
    },
    [theme.screen.laptop]: {
        ':hover': {
            background: 'rgba(0, 0, 0, 0.06)',
        },
    },
    [`& > .${flexClassName}`]: {
        [`& > .${flexClassName}`]: {
            minHeight: '26rem',
        },
    },
    [`& > .${flexClassName}:nth-child(3)`]: {
        marginTop: '18rem',
        minHeight: '13px',
    },
    [`& > :nth-child(4).${labelClassName}`]: {
        minHeight: '1.3em',
    },
    cursor: disableTileLink ? 'unset' : 'pointer',
});

export const ProjectTile: StyleRule<{
    disableTileLink?: boolean;
}> = ({ disableTileLink, theme }) => ({
    display: 'inline-grid',
    textDecoration: 'none',
    '& > * div': {
        color: theme.siteVariables.colorScheme.default!.foreground,
    },
    '& > *': {
        '&:hover': {
            '& > *': {
                textDecoration: 'underline',
            },
        },
    },
    cursor: disableTileLink ? 'unset' : 'pointer',
});

export const ProjectItem: StyleRule = () => ({
    '& > a': {
        display: 'block',
    },
});

export const VarintColor: StyleRule = () => ({
    borderRadius: pxToRem(50),
    display: 'inline-block',
    position: 'absolute',
});

export const TileWrapper: StyleRule<{ hasButton: boolean }> = ({
    hasButton,
    theme,
}) => ({
    marginleft: pxToRem(-14),
    marginRight: pxToRem(-14),
    '& > button': {
        display: 'flex',
        margin: 'auto',
    },

    '& > a': {
        textAlign: 'center',
    },
    [`& .${productTileClassName}`]: {
        ...(hasButton && { paddingBottom: 0 }),
    },
    [`& .${projectTileClassName}`]: {
        ...(hasButton && { paddingBottom: 0 }),
    },

    [`& .${tileGridClassName}`]: {
        marginBottom: pxToRem(35),
        paddingTop: pxToRem(5),
        [`& .${HeaderClassName}`]: {
            marginBottom: pxToRem(23),
            padding: pxArrayToRem([0, 0, 0, 18]),
            fontSize: theme.siteVariables.fontSizes['label01'],
            letterSpacing: theme.siteVariables.letterSpacingMedium,
            whiteSpace: 'unset',
            textAlign: 'left',
            color: theme.siteVariables.colorScheme.default?.foreground,

            // Bug applying maximumLines to header - remove once fixed
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',

            [theme.screen.min.tablet]: {
                fontSize: pxToRem(22),
                marginLeft: pxToRem(924),
                padding: pxArrayToRem([0, 0, 60, 0]),
                marginBottom: 0,
                whiteSpace: 'pre-line',
                textAlign: 'left',
                letterSpacing: theme.siteVariables.letterSpacingSmall,
            },
        },
        [theme.screen.min.tablet]: {
            marginBottom: pxToRem(52),
        },
    },
});

export const TileTop: StyleRule = () => ({
    marginBottom: pxToRem(101),
    marginTop: pxToRem(90),
    display: 'grid',
    gridTemplateColumns: `repeat(5, 1fr)`,

    '& > a': {
        textAlign: 'left',
    },
});

export const TileTopProject: StyleRule<{ hasButton: boolean }> = ({
    hasButton,
}) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    marginBottom: hasButton ? pxToRem(130) : 0,
});

export const ButtonSection: StyleRule = ({ theme }) => ({
    margin: `0 0 0 ${pxToRem(924)}`,
    '& > a': {
        display: 'flex',
        width: 'fit-content',
        padding: pxArrayToRem([24, 25.5]),
        border: `1px solid ${theme.siteVariables.colors.charcoal['250']}`,
        fontWeight: 500,
        letterSpacing: theme.siteVariables.letterSpacingMedium,
    },
});

export const MobileProjectTileWrapper: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([13, 0, -1, 0]),

    [`& .${projectTileClassName}`]: {
        marginBottom: pxToRem(60),
    },

    [`& .${labelClassName}.${projectTileSlotClassNames.projectName}`]: {
        fontSize: pxToRem(14),
        color: theme.siteVariables.colors.primitive.black,
    },

    [`& .${labelClassName}.${projectTileSlotClassNames.professionalName}`]: {
        fontSize: pxToRem(13),
        color: theme.siteVariables.colors.primitive.black,
    },

    [`& a.${buttonClassName}`]: {
        height: pxToRem(48),
        margin: pxArrayToRem([8, 18, 0, 18]),
        border: `1px solid ${theme.siteVariables.colors.charcoal[250]}`,
        [`& .${labelClassName}`]: {
            fontSize: pxToRem(16),
            color: theme.siteVariables.colors.charcoal[250],
        },
    },

    [`& .${tileImageClassName} .${flexClassName} > .${boxClassName}`]: {
        [`& .${buttonClassName}`]: {
            width: pxToRem(32),
            height: pxToRem(32),
            [`& > span > span`]: {
                width: pxToRem(16),
                height: pxToRem(16),
            },
        },
    },
});

export const MobileProductTileWrapper: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([13, 0, 40, 0]),
    gap: pxArrayToRem([40, 6]),

    [`& div.${labelClassName}`]: {
        fontSize: pxToRem(14),
        color: theme.siteVariables.colors.primitive.black,
    },

    [`& a.${productTileClassName}`]: {
        gridTemplateRows: 'auto auto 1fr',
    },

    [`& .${labelClassName}.${productTileSlotClassNames.title}`]: {
        display: 'none',
        color: theme.siteVariables.colors.charcoal['250'],
    },

    [`& .${labelClassName}.${productTileSlotClassNames.product}`]: {
        maxHeight: pxToRem(36),
        paddingRight: pxToRem(18),
        color: theme.siteVariables.colors.charcoal['250'],
    },

    [`& .${labelClassName}.${productTileSlotClassNames.price}`]: {
        maxHeight: pxToRem(18),
        wordSpacing: pxToRem(5),
        color: theme.siteVariables.colors.charcoal['250'],
    },

    [`& div.${productTileSlotClassNames.variants}`]: {
        maxHeight: pxToRem(15),
    },

    [`& .${tileImageClassName} .${flexClassName} > .${boxClassName}`]: {
        [`& .${buttonClassName}`]: {
            width: pxToRem(32),
            height: pxToRem(32),
            [`& > span > span`]: {
                width: pxToRem(16),
                height: pxToRem(16),
            },
        },
    },
});

export const ViewAllButton: StyleRule = ({ theme }) => ({
    height: pxToRem(48),
    margin: pxArrayToRem([0, 18, 0, 18]),
    border: `1px solid ${theme.siteVariables.colors.charcoal['000']} !important`,
    '&:hover': {
        border: `1px solid ${theme.siteVariables.colors.charcoal['000']} !important`,
    },
    '&:active': {
        border: `1px solid ${theme.siteVariables.colors.charcoal['000']} !important`,
    },
    [`& div.${labelClassName}`]: {
        fontSize: pxToRem(18),
        lineHeight: 1,
        letterSpacing: 0,
        color: theme.siteVariables.colors.charcoal['000'],
    },
});
