import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    boxClassName,
    buttonClassName,
    labelClassName,
} from '@archipro-design/aria';

export const aboutDescWrapper: StyleRule = ({ theme }) => {
    return {
        [`& > a`]: {
            display: 'flex',
            justifyContent: 'flex-start',
            color: `inherit`,
            textDecoration: `underline`,

            '&:hover': {
                color: theme.siteVariables.colorScheme.default?.foregroundHover,
            },
        },
        [theme.screen.laptop]: {
            width: pxToRem(896),
            color: theme.siteVariables.colorScheme.default?.foreground,
        },
        [theme.screen.clamped]: {
            width: pxToRem(996),
        },
    };
};

export const toggleButton: StyleRule = ({ theme }) => {
    return {
        [`& > a`]: {
            textTransform: 'unset',
            fontSize: theme.siteVariables.fontSizes['label03-alt'],
            fontWeight: theme.siteVariables.fontWeightLight,
            letterSpacing: theme.siteVariables.letterSpacingSmall,
            lineHeight: theme.siteVariables.lineHeightDefault,
        },
        textUnderlineOffset: pxToRem(3),
        color: theme.siteVariables.colorScheme.default?.foreground,
        width: 'fit-content',
        '> *': {
            padding: 0,
        },

        fontSize: theme.siteVariables.fontSizes.caption03,
        [theme.screen.laptop]: {
            fontSize: pxToRem(26),
        },
    };
};

export const ReadMoreText: StyleRule = ({ theme }) => {
    return {
        [`& > .${boxClassName} p`]: {
            marginTop: pxToRem(-2),
            [theme.screen.laptop]: {
                marginTop: 0,
                fontWeight: theme.siteVariables.fontWeightLight,
            },
        },

        [`& .${buttonClassName}`]: {
            padding: 0,
            marginTop: pxToRem(20),
            borderBottomWidth: pxToRem(1),
            [`& .${labelClassName}`]: {
                textTransform: 'unset',
                fontSize: theme.siteVariables.fontSizes.caption03,
                letterSpacing: 0,
                lineHeight: theme.siteVariables.lineHeightDefault,
                textDecorationThickness: pxToRem(1),
                [theme.screen.laptop]: {
                    lineHeight: theme.siteVariables.lineHeightSmall,
                    fontWeight: theme.siteVariables.fontWeightLight,
                },
            },
            '&:hover': {
                color: theme.siteVariables.colorScheme.default?.foregroundHover,
                backgroundColor: 'transparent',
            },
            textUnderlineOffset: pxToRem(3),
            color: theme.siteVariables.colorScheme.default?.foreground,
            width: 'fit-content',
            '> *': {
                padding: 0,
            },

            [theme.screen.laptop]: {
                lineHeight: theme.siteVariables.lineHeightSmall,
                fontWeight: theme.siteVariables.fontWeightLight,
                marginTop: pxToRem(19),
            },
        },

        [`& > div.${boxClassName}`]: {
            '& a': {
                color: theme.siteVariables.colorScheme.default?.foreground,
                textDecoration: `underline`,

                '&:hover': {
                    color: theme.siteVariables.colorScheme.default
                        ?.foregroundHover,
                },
            },
            '& p': {
                marginTop: pxToRem(0),
                [theme.screen.laptop]: {
                    marginTop: 0,
                    fontWeight: theme.siteVariables.fontWeightLight,
                },
            },
        },
    };
};

export const readMoreDivider: StyleRule = ({ theme }) => {
    return {
        height: pxToRem(40),
        [theme.screen.laptop]: {
            height: pxToRem(90),
        },
    };
};
