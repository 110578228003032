import { Caption, Flex, useStyles, useTheme } from '@archipro-design/aria';
import * as S from './AvailableForWork.style';

interface Props {
    mobile?: boolean;
}
const AvailableForWork = ({ mobile }: Props) => {
    const { css } = useStyles();
    const theme = useTheme();
    return (
        <Flex vAlign="center">
            <div className={css(S.Indicator)}></div>
            <Caption
                variant={mobile ? '04' : '03'}
                weight={mobile ? 'regular' : 'medium'}
                variables={{
                    captionColor: mobile
                        ? theme.siteVariables.colors.primitive.black
                        : theme.siteVariables.colors.charcoal[250],
                }}
            >
                Available for work
            </Caption>
        </Flex>
    );
};

export default AvailableForWork;
