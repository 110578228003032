import type { StyleRule } from '@archipro-design/aria';
import { captionClassName } from '@archipro-design/aria';
import { listItemClassName } from '@archipro-design/aria';
import { boxClassName } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';

export const DownloadList: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([8, 0, 0, 0]),
    padding: pxArrayToRem([0, 18, 0, 18]),
    [theme.screen.laptop]: {
        margin: 0,
        padding: 0,
    },
    [`& .${listItemClassName}`]: {
        '&:first-child': {
            [theme.screen.laptop]: {
                borderTop: `2px solid ${theme.siteVariables.colors.grey[125]}`,
            },
        },
        borderBottom: `1px solid ${theme.siteVariables.colors.grey[125]}`,
        [theme.screen.laptop]: {
            borderBottom: `2px solid ${theme.siteVariables.colors.grey[125]}`,
        },

        '& > a': {
            display: `flex`,
            minHeight: pxToRem(56),
            alignItems: 'flex-start',
            [theme.screen.laptop]: {
                alignItems: 'center',
                height: pxToRem(56),
                padding: 0,
            },

            [`& .${boxClassName}`]: {
                margin: pxArrayToRem([18, 16, 0, 0]),
                '& span': {
                    width: pxToRem(20),
                    height: pxToRem(20),
                },
                [theme.screen.laptop]: {
                    margin: pxArrayToRem([0, 40, 0, 0]),
                    '& span': {
                        width: pxToRem(24),
                        height: pxToRem(24),
                    },
                },
            },

            '&:hover': {
                background: `rgba(255,255,255,0.1)`,
            },

            [`& .${captionClassName}`]: {
                padding: pxArrayToRem([16, 0, 16, 0]),
                fontSize: pxToRem(16),
                lineHeight: 1.5,
                width: pxToRem(350),
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',

                [theme.screen.laptop]: {
                    fontSize: pxToRem(16),
                    lineHeight: 1.1,
                    padding: pxArrayToRem([20, 0]),
                    width: 'unset',
                },
            },
        },
    },
});
