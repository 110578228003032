import { useAppDisplayModeContext, useStyles } from '@archipro-design/aria';
import { useLoaderData } from '@remix-run/react';
import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';
import { BusinessFocusEnum } from 'generated/graphql';
import { MainInfoLeftSectionItem } from './MainInfoLeftSectionItem';
import * as S from './MainInformation.style';
import InfoSectionMobile from '~/modules/detail/components/info-section/InfoSectionMobile';

const getFocusText = (name?: BusinessFocusEnum): string => {
    if (BusinessFocusEnum.Mixed === name) {
        return 'Residential & Commercial';
    }
    if (BusinessFocusEnum.Commercial === name) {
        return 'Commercial';
    }
    if (BusinessFocusEnum.Residential === name) {
        return 'Residential';
    }
    return '';
};
export const BusinessFocus: React.FC = () => {
    const { css } = useStyles();
    const { professionalOverview } = useLoaderData<OverviewLoaderData>();
    const focusText = getFocusText(professionalOverview?.BusinessFocus);
    const { mobile: isMobile } = useAppDisplayModeContext();

    if (!focusText) {
        return null;
    }

    return isMobile ? (
        <InfoSectionMobile label="Business Focus">
            {focusText}
        </InfoSectionMobile>
    ) : (
        <MainInfoLeftSectionItem title="Business" subTitle="Focus">
            <div className={css(S.SectionText)}>{focusText}</div>
        </MainInfoLeftSectionItem>
    );
};
