import { useAppDisplayModeContext } from '@archipro-design/aria';
import { useLoaderData } from '@remix-run/react';
import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';
import { MainInfoLeftSectionItem } from '@modules/professional/component/overview-tab/main-info/MainInfoLeftSectionItem';
import InfoSectionMobile from '~/modules/detail/components/info-section/InfoSectionMobile';
import { useMatchesHandleData } from '@modules/root';

export const ProjectsPublished: React.FC = () => {
    const { professionalOverview } = useLoaderData<OverviewLoaderData>();
    const { mobile: isMobile } = useAppDisplayModeContext();
    const isBMPreview = useMatchesHandleData('isBMPreview', false);

    const projectsPublished = professionalOverview?.ProjectCount || 0;

    return projectsPublished > 0 ? (
        isMobile ? (
            <InfoSectionMobile label="Projects published">
                {projectsPublished}
            </InfoSectionMobile>
        ) : (
            <MainInfoLeftSectionItem
                title="Projects"
                subTitle="published"
                totalNumber={projectsPublished}
                link={
                    isBMPreview
                        ? 'case-studies'
                        : `${professionalOverview.Link}/case-studies`
                }
            />
        )
    ) : null;
};
