import { useAppDisplayModeContext } from '@archipro-design/aria';
import type { FeaturedProductsType } from '@modules/professional/type';
import { FeaturedProductsMobile } from '@modules/professional/component/overview-tab/featured-items/FeaturedProducts.mobile';
import { FeaturedProductsDesktop } from '@modules/professional/component/overview-tab/featured-items/FeaturedProducts.desktop';

export interface FeaturedProductsProps {
    products: FeaturedProductsType;
    openInNewTab?: boolean;
    disableTileLink?: boolean;
    disableSaveToDesignBoard?: boolean;
}

export const FeaturedProducts = (props: FeaturedProductsProps) => {
    const { desktop } = useAppDisplayModeContext();

    if (desktop) {
        return <FeaturedProductsDesktop {...props} />;
    }

    return <FeaturedProductsMobile {...props} />;
};
